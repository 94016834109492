import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../services/misc/auth.service';
import { authEndpoints } from '../../auth-endpoints.config';

export const injectTokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);

  const getEndpointFromURL = (url: string) => '/' + url.split('//').join('').split('?')[0].split('/').filter((y, i) => i > 0).join('/');

  const endpointRequiresAuth = authEndpoints.some(
    (x) =>
      req.url.startsWith(x.baseURL)
      && (x.type === 'match'
        ? getEndpointFromURL(req.url) === x.endpoint
        : getEndpointFromURL(req.url).startsWith(x.endpoint)
      )
  )

  if (endpointRequiresAuth && authService.hasValidAccessToken()) {
    return next(
      req.clone({
        headers: req.headers.set('Authorization', `Bearer ${authService.getAccessToken()}`),
      })
    )
  }

  return next(req);
};