import { HttpEventType, HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs';
import { ApiResponse, PaginatedItems } from '../interfaces/generic/api-response';
import {
  EnumResourceTypeCategory,
  Resource,
  ResourceWithCuratedData,
  Manifestation,
  CuratedResource,
  CuratedResourceData,
  CuratedResourceDatasource,
  CuratedResourceInteroperability,
  CuratedResourceOtherProduct,
  CuratedResourcePublication,
  CuratedResourceService,
  CuratedResourceSoftware,
  CuratedResourceTraining,
} from '../interfaces/resource';
import { cloneDeep, isArray } from 'lodash';

const _curateResourceData = (resource: Resource): ResourceWithCuratedData => {
  let curatedData: Omit<CuratedResource, 'extras'> & { extras : {} } = {
    id: resource.id,
    type: resource.catalogue,
    title: '',
    descriptions: [],
    curationStatus: resource.source.curation?.status || null,
    extras: {},
  };

  switch (resource.catalogue) {
    case EnumResourceTypeCategory.PUBLICATIONS:
    case EnumResourceTypeCategory.SOFTWARE:
    case EnumResourceTypeCategory.DATA:
    case EnumResourceTypeCategory.OTHER_PRODUCTS: {
      curatedData = {
        ...curatedData,

        title: resource.source?.titles?.none?.[0] || '',
        descriptions: resource.source?.abstracts?.none && isArray(resource.source.abstracts.none)
          ? resource.source.abstracts.none.filter((x: string) => x)
            .sort((a: string, b: string) => b.length - a.length)
          : [],

        extras: {
          authors: resource.source?.contributions && isArray(resource.source.contributions)
            ? resource.source.contributions.map((x: { person?: { full_name: string } }) => x.person?.full_name || '').filter((x: string) => x)
            : [],
          publishers: resource.source?.manifestations && isArray(resource.source.manifestations)
            ? [...new Set(resource.source.manifestations.map((x: {hosting_datasource?: { name: string } }) => x.hosting_datasource?.name || '').filter((x: string) => x))]
            : [],
          identifiers: resource.source?.identifiers && isArray(resource.source.identifiers)
            ? resource.source.identifiers.map((x: { value: string }) => x.value || '').filter((x: string) => x)
            : [],
          year: resource.source?.manifestations && isArray(resource.source.manifestations)
            ? (() => {
              const arr: number[] = resource.source.manifestations
                .map((m: { dates: { type: string, value: string }[] }) =>
                  isArray((m.dates))
                    ? m.dates.filter((d) => d.type === 'publishing' && d.value).map((d) => d.value)
                    : []
                )
                  .flat().map((x: string) => parseInt(x.split('-')[0]));

              return arr.length === 0 ? null : Math.min(...arr);
            })()
            : null,
          manifestations: resource.source?.manifestations && isArray(resource.source.manifestations) && resource.source?.identifiers && isArray(resource.source.identifiers)
            ? resource.source?.manifestations
                .map((x: {
                  dates: { type: string, value: string }[],
                  hosting_datasource: { name: string },
                  licence: string,
                  pid: string,
                  product_local_type: string,
                  url: string,
                }) => ({
                  productType: x.product_local_type,
                  date: x.dates && isArray(x.dates) ? x.dates.filter((d) => d.type === 'publishing').map((d) => d.value).filter((d) => d).sort()[0] || null : null,
                  license: x.licence,
                  host: x.hosting_datasource?.name,
                  url: x.url,
                  identifierValue: (resource.source.identifiers as { scheme: string, value: string }[])
                    .find((identifier) => identifier?.value === x.pid)?.value,
                  identifierScheme: (resource.source.identifiers as { scheme: string, value: string }[])
                    .find((identifier) => identifier?.value === x.pid)?.scheme,
                }))
                  .filter((x: Manifestation) => x.url)
            : [],
          productType: resource.source?.manifestations && isArray(resource.source.manifestations)
            ? [...new Set(resource.source.manifestations.map((x: {product_local_type: string}) => x.product_local_type))]
            : [],
          keywords: resource.source?.keyword && isArray(resource.source.keyword) ? resource.source.keyword : [],
          accessRights: resource.source?.accessRights && isArray(resource.source.accessRights) ? resource.source.accessRights : [],
          downloads: parseInt(resource.source?.indicator?.downloadsAndViews?.downloads) ?? null,
          views: parseInt(resource.source?.indicator?.downloadsAndViews?.views) ?? null,
        }
      } as CuratedResourcePublication | CuratedResourceSoftware | CuratedResourceData | CuratedResourceOtherProduct;
      break;
    }
    case EnumResourceTypeCategory.SERVICES:
    case EnumResourceTypeCategory.DATASOURCES: {
      curatedData = {
        ...curatedData,

        title: resource.source?.name || '',
        descriptions: resource.source?.description ? [resource.source?.description] : [],

        extras: {
          organization: resource.source?.organization || '',
          scientificDomains: resource.source?.domain && isArray(resource.source.domain) ? resource.source.domain.map((x: { domain: string }) => x.domain || '').filter((x: string) => x) : [],
          interoperabilityGuidelines: resource.source?.interoperabilityGuidelines && isArray(resource.source.interoperabilityGuidelines) ? resource.source.interoperabilityGuidelines : [],
          languages: resource.source?.language && isArray(resource.source.language) ? resource.source.language : [],
          keywords: resource.source?.tags && isArray(resource.source.tags) ? resource.source.tags : [],
          url: resource.source?.webpage || '',
          accessRights: resource.source?.orderType ? [resource.source?.orderType] : [],
        }
      } as CuratedResourceService | CuratedResourceDatasource;
      break;
    }
    case EnumResourceTypeCategory.INTEROPERABILITY: {
      curatedData = {
        ...curatedData,

        title: resource.source?.title || '',
        descriptions: resource.source?.description ? [resource.source?.description] : [],

        extras: {
          provider: resource.source?.organization || null,
          year: resource.source?.publicationYear ?? null,
          license: resource.source?.license || null,
          url: resource.source?.doi || null,
        }
      } as CuratedResourceInteroperability;
      break;
    }
    case EnumResourceTypeCategory.TRAINING: {
      curatedData = {
        ...curatedData,

        title: resource.source?.title || '',
        descriptions: resource.source?.description ? [resource.source?.description] : [],

        extras: {
          authors: resource.source?.authors && isArray(resource.source.authors) ? resource.source.authors : [],
          organization: resource.source?.organization || null,
          contentTypes: resource.source?.contentResourceType && isArray(resource.source.contentResourceType) ? resource.source.contentResourceType : [],
          scientificDomains: resource.source?.domain && isArray(resource.source.domain) ? resource.source.domain.map((x: { domain: string }) => x.domain || '').filter((x: string) => x) : [],
          languages: resource.source?.language && isArray(resource.source.language) ? resource.source.language : [],
          license: resource.source?.license || null,
          keywords: resource.source?.keyword && isArray(resource.source.keyword) ? resource.source.keyword : [],
          url: resource.source?.url || null,
          productType: resource.source?.learningResourceType && Array.isArray(resource.source.learningResourceType) ? [...new Set(resource.source.learningResourceType)] : [],
          accessRights: resource.source?.accessRights ? [resource.source?.accessRights] : [],
        }
      } as CuratedResourceTraining;
      break;
    }
    default: {
      console.log('NOT HANDLED:', resource.catalogue)
    }
  }

  return {
    ...resource,
    curatedData,
  } as ResourceWithCuratedData;
}

export const curateResourceDataInterceptor: HttpInterceptorFn = (req, next) => {

  const isEndpointGetResources = req.url.endsWith('/action/catalogue/items') || req.url.includes('/action/catalogue/items?');
  const isEndpointGetSingleResource = new RegExp(`/action/catalogue/(.+)/items?(.+)id=(.+)`).test(req.url);

  // CURATE ITEMS
  if (isEndpointGetResources || isEndpointGetSingleResource) {
    return next(req)
      .pipe(
        map(
          (x) => {
            if (x.type !== HttpEventType.Response) return x;

            const newResponse = cloneDeep(x);

            if (!newResponse.body || !x.body) return x;

            if (isEndpointGetResources) {
              (newResponse as HttpResponse<ApiResponse<PaginatedItems<Resource>>>).body!.result.items = (x as HttpResponse<ApiResponse<PaginatedItems<Resource>>>).body!.result?.items
                ? (x as HttpResponse<ApiResponse<PaginatedItems<Resource>>>).body!.result.items
                  .map((x) => _curateResourceData(x))
                : [];
            } else if (isEndpointGetSingleResource) {
              // (newResponse as HttpResponse<ApiResponse<ResourceWithCuratedData>>).body!.result = _curateResourceData(
              //   {
              //     id: (x as HttpResponse<ApiResponse<Resource>>).body!.result.id,
              //     source: (x as HttpResponse<ApiResponse<Resource>>).body!.result,
              //     catalogue: req.url.split('/action/catalogue/')[1].split('/items')[0] as EnumResourceTypeCategory,
              //   }
              // )
              (newResponse as HttpResponse<ApiResponse<ResourceWithCuratedData>>).body!.result = _curateResourceData((x as HttpResponse<ApiResponse<Resource>>).body!.result);
            }

            return newResponse;
          }
        ),
      );
  }

  return next(req);
};