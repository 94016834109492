import { environment } from '../environments/environment';

export const authEndpoints: {
  baseURL: string;
  endpoint: string;
  type: 'match' | 'prefix';
}[] = [
  {
    baseURL: environment.apiUrl,
    endpoint: '/action/catalogue',
    type: 'prefix',
  },
  {
    baseURL: environment.adminApiUrl,
    endpoint: '/',
    type: 'prefix',
  },
  {
    baseURL: environment.apiUrl,
    endpoint: environment.toolApiEndpoint,
    type: 'prefix',
  },
  {
    baseURL: environment.apiUrl,
    endpoint: '/oms/action/user',
    type: 'prefix',
  },
];
