import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpEventType } from '@angular/common/http';
import { Observable, catchError, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ApiResponse } from '../interfaces/generic/api-response';
import { ModalGenericErrorComponent } from '../../shared/components/modal-generic-error/modal-generic-error.component';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {

  constructor(private dialog: MatDialog) {}

  openErrorModal(): void {
    this.dialog.open(ModalGenericErrorComponent, { disableClose: true });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(() => {
        this.openErrorModal();
        return new Observable<HttpEvent<unknown>>();
      }),
      tap((x) => {
        if (x.type === HttpEventType.Response) {
          if (
            !`${x.status}`.startsWith('2')
            || (x.body as ApiResponse<unknown>)?.success === false
          ) {
            this.openErrorModal();
          }
        }
      })
    );
  }
}
